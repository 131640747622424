import React from "react"
import "./mystyles.scss"
import Layout from '../components/Layout'
import ServicesCards from "../components/ServicesCards"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import SEO from "../components/SEO"


export default function Home() {
  return (
    <Layout>
        <SEO />
        <div className="container has-background-dark">
          {/* hero section */}
          <div className="columns is-8">
            <div className="column is-5 has-text-centered">
              <h1 className="title has-text-primary py-5 is-size-2">Elevate Your Leadership with Top Talent</h1>
              <h2 className="subtitle has-text-primary is-size-4">by increasing Your Energy, Mental Fitness and Positive Intelligence through Coaching and Leadership Development</h2>
              <p className="has-text-primary pb-5 is-size-5">We partner with individuals and organizations to develop positive mindsets, leadership capabilities and talent practices</p>
              <a href="https://appointmentthing.com/judymchugh" className="button has-background-warning mx-3">Schedule your <span className="has-text-weight-bold px-1"> FREE </span> consultation</a>
              <Link to="/contact" className="button has-text-dark has-background-warning px-3">Contact Judy</Link>
            </div>
            <div className="column is-7 is-hidden-mobile">
              <StaticImage 
                src="../images/photo-of-people-holding-each-other-s-hands-3184434.jpg"
                alt="Photo of people with their hands together."
                placeholder="blurred"
              />
            </div>
          </div>
          {/* Work with section */}
          <section className="section has-background-light">
            <h3 className="title">Work with Top Talent</h3>
            <div className="columns">
              <div className="column is-4"> 
                <StaticImage 
                  src="../images/judy-black-full.jpg"
                  alt="Judy McHugh standing in black suit"
                  placeholder="blurred"
                />
              </div>
              <div className="column is-8 py-5">
                <p className="py-3">Judy McHugh is the founder and principal of Top Talent, LLC, a firm focused on individual and team coaching, leadership development and talent/performance management consulting. She has worked as a coach, consultant and leader at organizations like the Wharton School's Aresty Institute for Executive Education, as Executive Director; MetLife as VP, Global Talent Development and The Linde Group, heading up Talent Management and Development.</p>
                <p className="py-3">Judy's educational background includes an MBA from Drexel University and a Doctor of Education degree from the University of Pennsylvania in a program co-sponsored by the Wharton School of Business and the Graduate School of Education focused on corporate learning. As a Professional Coach and a SHRM Senior HR Professional, Judy brings up-to-date knowledge, skills and tools to help clients achieve exceptional leadership and performance outcomes. </p>
                <p className="py-3">Judy is certified in Hogan assessments, Human Synergistic's culture tools, iPEC's Energy Leadership Index and unconscious bias training. She has also designed and delivered numerous programs in all areas of leadership development. She is on the Alumni Board of the University of Pennsylvania's Chief Learning Officer's Doctorate Program and previously spent six years on the Board of Directors for Children's Literacy Initiative, a non-profit dedicated to developing teachers' abilities to ensure students are learning to read at a young age.</p>
                <p className="py-3">With over 25 years of experience, Judy has become known for developing leadership capabilities and talent processes for businesses. She coaches individual contributors, managers and executives through empowering discovery conversations to increase their energy level, engage in positive thinking, and leverage their strengths leading to enhanced leadership skills, productivity, happiness, and success. Judy also collaborates with companies to build strong leadership pipelines by designing and facilitating talent reviews, succession planning and performance management processes, as well as leadership development solutions.</p>
              </div>
            </div>
            <div className="has-text-centered">
              <p className="subtitle py-3 has-text-weight-bold">Join the many companies that have worked with Judy to improve employee performance by identifying, developing, retaining and growing top talent.</p>
              <StaticImage 
                src="../images/CompanyLogos-2500x600USE.png"
                alt="logos of companies Judy McHugh has worked with"
                placeholder="blurred"
              />
            </div>
          </section>
          <ServicesCards />
        </div>
    </Layout>
  )
}
